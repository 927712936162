import {Sidebar} from "./sidebar/Sidebar";
import {Notification} from "./notification/Notification";

export const Skeleton = () => {
    return (
        <>
            <Sidebar/>
            <Notification/>
        </>
    );
};
