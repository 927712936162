import BaseObject from "../../BaseObject";

export default class NotificationBell extends BaseObject {
    notificationBell: INotificationBell[];

    constructor(notificationBell: INotificationBell[]) {
        super();
        this.notificationBell = notificationBell;
    }
}

export interface INotificationBell {
    id: string;
    typeOfNotification: string;
    title: string;
    message: string;
    seen: boolean;
}