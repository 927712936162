import React, {useState} from "react";
import CreateAccessToken from "../../backend/wrapper/auth/CreateAccessToken";
import GeneralResponse from "../../backend/GeneralResponse";
import {FormPage} from "../../elements/form/FormPage";
import {FormItem} from "../../elements/form/FormItem";

export const Login = () => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    async function validateData(): Promise<string | undefined> {
        return undefined;
    }

    async function submit(): Promise<string | undefined> {
        const token = await CreateAccessToken.request(username, password);
        if (token instanceof GeneralResponse) return token.text;
        else {
            localStorage.setItem("token", `Bearer ${token}`);
            window.location.href = "/";
        }
    }

    return (
        <main id={"small-box"} className={"right-blue-border"}>
            <FormPage
                title={"LOGIN"}
                currentState={"submit"}
                validateData={validateData}
                submit={submit}
            >
                <FormItem
                    id={"username"}
                    label={"Username"}
                    inputType={"text"}
                    required={true}
                    onChange={setUsername}
                    initialValue={""}
                />
                <FormItem
                    id={"password"}
                    label={"Password"}
                    inputType={"password"}
                    required={true}
                    onChange={setPassword}
                    initialValue={""}
                />
            </FormPage>
        </main>
    );
};
