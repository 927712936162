import UserWrapper from "../UserWrapper";
import DetailedResponse from "../../../DetailedResponse";
import EventList, {IEventProgrammeList} from "../../../model/user/events/EventList";

class GetEventList extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<EventList>> {
        let request = await this.detailedFormattedRequest<IEventProgrammeList[]>(
            `/events`,
            "GET",
            token,
            undefined
        );

        if(request.status !== 200) return new DetailedResponse<EventList>(request.status, request.text, request.additionalInformation);

        let eventList = new EventList(request.additionalInformation);
        return new DetailedResponse<EventList>(request.status, request.text, eventList);
    }
}

export default (new GetEventList());