import {FormPage} from "../../../elements/form/FormPage";
import {FormItem} from "../../../elements/form/FormItem";
import {useState} from "react";
import {default as AssignTeamLogoWrapper} from "../../../backend/wrapper/team/assign/AssignTeamLogo";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {useParams} from "react-router-dom";

export const AssignTeamLogo = () => {
    const [files, setFiles] = useState<FileList | undefined>(undefined);
    const {code} = useParams();

    async function validateData(): Promise<string | undefined> {
        if (files?.length !== 1) return "Please upload the logo.";
        else if (files[0].size > 4 * 1024 * 1024)
            return "The image cannot be larger than 4MB.";
        else if (files[0].type !== "image/png")
            return "Please make sure that the image is of type PNG.";
    }

    async function submit(): Promise<string | undefined> {
        if (!files) return;

        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        let request = await AssignTeamLogoWrapper.request(
            files[0],
            code as string,
            userCache.token
        );
        if (request.status === 200) {
            TokenUserCache.invalidateCache([userCache.token]);
            window.location.href = "/team";
        } else return request.text;
    }

    return (
        <main id={"small-box"} className={"right-blue-border"}>
            <FormPage
                title={"ASSIGN TEAM LOGO"}
                currentState={"submit"}
                validateData={validateData}
                submit={submit}
            >
                <FormItem
                    id={"image"}
                    label={"Logo"}
                    inputType={"file"}
                    accept={"image/png"}
                    required={true}
                    onChange={setFiles}
                    initialValue={""}
                />
            </FormPage>
        </main>
    );
};
