import {GenericBox} from "../../../elements/GenericBox";
import '../../skeleton/notification/notification.css';
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import parse from "html-react-parser";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";

export const Notification = () => {
    const [title, setTitle] = React.useState<undefined | JSX.Element>(undefined);
    const [text, setText] = React.useState<undefined | JSX.Element>(undefined);
    const {id} = useParams();

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.getNotification(id as string).then((notification) => {
            TokenUserCache.invalidateCache([userCache.token]); //Invalidate the cache
            if (notification === undefined) return;

            let formattedStartDate = new Date(notification.startDate);

            setTitle(
                <section className={"notification-title"}>
                {notification.title}
                <span className={"start-date"}>Posted On: {formattedStartDate.toLocaleDateString('en-GB')}</span>
                </section>
            );


            setText(parse(notification.message) as JSX.Element);
        });
    }, [id]);

    return <GenericBox title={title} text={text} image={undefined}/>;
};
