import TeamWrapper from "../TeamWrapper";
import DetailedResponse from "../../../DetailedResponse";
import ShareRegistryInformation, {
    IShareRegistryInformation,
    IViewableShareholderCertificate
} from "../../../model/team/ShareRegistryInformation";

class GetSingleShareInformation extends TeamWrapper {
    async request(
        id: string,
        token: string
    ): Promise<DetailedResponse<IViewableShareholderCertificate>> {
        let request = await this.detailedFormattedRequest<IViewableShareholderCertificate>(`/shareregistry/${encodeURIComponent(id)}`, "GET", token, undefined);
        if (request.status !== 200) return request as any;
        return new DetailedResponse<IViewableShareholderCertificate>(
            request.status,
            request.text,
            request.additionalInformation
        );
    }
}

export default (new GetSingleShareInformation());