import BaseObject from "../BaseObject";

export default class User extends BaseObject implements IUser {
    address: any;
    dateOfBirth: number;
    email: string;
    idNumber: string;
    mobile: string;
    name: string;
    token: string;
    description: string;
    userType: string;

    constructor(user: IUser) {
        super();

        this.name = user.name;
        this.dateOfBirth = user.dateOfBirth;
        this.idNumber = user.idNumber;
        this.mobile = user.mobile;
        this.email = user.email;
        this.address = user.address;
        this.token = user.token;
        this.description = user.description;
        this.userType = user.userType;
    }
}

export interface IUser {
    name: string;
    dateOfBirth: number;
    idNumber: string;
    mobile: string;
    email: string;
    address: any;
    token: string;
    description: string;
    userType: string;
}

export interface IPublicContactInformation {
    name: string;
    mobile: string;
    email: string;
    description: string;
}