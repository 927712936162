import UserWrapper from "../UserWrapper";
import GeneralResponse from "../../../GeneralResponse";

class ChangeUserInformation extends UserWrapper {
    async request(
        dateOfBirth: string,
        email: string,
        phoneNumber: string,
        description: string,
        token: string
    ): Promise<GeneralResponse> {
        let body: any = {};
        if (dateOfBirth !== undefined) body["dateOfBirth"] = dateOfBirth;
        if (email !== undefined) body["email"] = email;
        if (phoneNumber !== undefined) body["phoneNumber"] = phoneNumber;
        if (description !== undefined) body["description"] = description;

        return await this.formattedRequest(
            "/change/salesforce",
            "PATCH",
            token,
            body
        );
    }
}

export default new ChangeUserInformation();