import TeamWrapper from "./TeamWrapper";
import Teams, {ITeam} from "../../model/team/Teams";
import DetailedResponse from "../../DetailedResponse";

class GetTeams extends TeamWrapper {
    async request(token: string): Promise<DetailedResponse<Teams>> {
        let request = await this.detailedFormattedRequest<ITeam[]>(
            "",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        let teams = new Teams(request.additionalInformation);
        return new DetailedResponse(request.status, request.text, teams);
    }
}

export default new GetTeams();