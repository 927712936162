import UserWrapper from "./UserWrapper";
import User, {IUser} from "../../model/user/User";
import DetailedResponse from "../../DetailedResponse";

class GetUser extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<User>> {
        let request = await this.detailedFormattedRequest<IUser>(
            "",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        return new DetailedResponse<User>(
            request.status,
            request.text,
            new User(request.additionalInformation)
        );
    }
}

export default new GetUser();