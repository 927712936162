import React from "react";
import {Link} from "react-router-dom";
import {INotificationBell} from "../../../backend/model/user/viewables/NotificationBell";

export const NotificationItem = (props: INotificationBell) => {
    let title = !props.seen ? (
        <>
            <i className="fas fa-bell-exclamation"/> &nbsp;
            <b>{props.typeOfNotification}</b>
        </>
    ) : (
        <>{props.typeOfNotification}</>
    );

    // Assign the classes
    let className = props.seen ? "unseen " : "seen ";

    switch (props.typeOfNotification) {
        case "Urgent":
        case "Deadline":
            className += "urgent";
            break;
        case "Reminder":
        case "General":
        case "Event":
        case "Feature":
        case "Upload":
            className += "general";
            break;
        default:
            className += "";
            break;
    }
    // Assign the classes
    return (
        <>
            <hr/>
            <section className={"notification-area"}>
                <Link className={className} to={`/notification/${props.id}`}>
                    <h3>{title}</h3>
                    <p>{props.message}</p>
                </Link>
            </section>
        </>
    );
};

/*
export class NotificationItem extends React.Component<INotificationBell, INotificationBell>{
    render() {

    }

    componentDidMount() {
        this.setState(this.props);
    }
}

 */