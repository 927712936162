import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import getArticle from "../../../backend/wrapper/user/content/GetArticle";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {useCountdown} from "../../../hooks/useCountdown";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import markArticleRead from "../../../backend/wrapper/user/content/MarkArticleRead";

export const ReadContent = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [readingDate, setReadingDate] = useState<Date>(new Date());
    const [title, setTitle] = useState<string>("E-Content");
    const [html, setHtml] = useState<string>("");
    const [css, setCss] = useState<string>("");

    const [read, setRead] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [days, hours, minutes, seconds] = useCountdown(readingDate);
    const [timePassed, setTimePassed] = useState<boolean>(false);


    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(localStorage.getItem("token") as string);
        getArticle.request(id as string, userCache.token).then(request => {
            if (request === undefined) return;

            setRead(request.additionalInformation.read);
            setTitle(request.additionalInformation.name);
            setHtml(request.additionalInformation.article.html);
            setCss(request.additionalInformation.article.css);

            let targetDate = new Date();
            targetDate.setSeconds(targetDate.getSeconds() + request.additionalInformation.readingTime + 2);
            setReadingDate(targetDate);

            setLoaded(true);
        });
    }, [id]);

    useEffect(() => {
        days + hours + minutes + seconds <= 0 || read ? setTimePassed(true) : setTimePassed(false);
    }, [days, hours, minutes, seconds, read]);

    function markRead() {
        let userCache = TokenUserCache.getUserCache(localStorage.getItem("token") as string);

        markArticleRead.request(id as string, userCache.token).then(value => {
            TokenUserCache.invalidateCache([userCache.token]);
            navigate("/content");
        })
    }

    return (
        <Dashboard
            title={title}
            className={"content"}
            noSlider={true}
        >
            <FullInformation key={"article"} active={true}>
                {loaded ?
                    <>
                        <div dangerouslySetInnerHTML={{__html: `<style>${css}</style>`}}></div>
                        <div dangerouslySetInnerHTML={{__html: html}}></div>

                        <div className={"finished"}>
                            <button className={timePassed ? "" : "grey"} disabled={!timePassed}
                                    onClick={markRead}>Done
                            </button>
                            &nbsp;
                            {timePassed ? <></> : <span><i className={"fa-regular fa-timer fa-1x"}/> {minutes} minutes and {seconds} seconds</span>}
                        </div>
                    </> : undefined}
            </FullInformation>
        </Dashboard>
    )
}