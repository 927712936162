import AdminWrapper from "../AdminWrapper";
import GeneralResponse from "../../../GeneralResponse";

class DuplicateArticle extends AdminWrapper {
    async request(id: string, token: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/article/${encodeURIComponent(id)}/duplicate`,
            "POST",
            token,
            undefined
        );
    }

}

export default new DuplicateArticle();