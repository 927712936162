import React, {Dispatch, SetStateAction} from "react";
import {FormItem} from "../form/FormItem";

interface IEditableSave {
    editMode: boolean | undefined;
    setEditMode: Dispatch<SetStateAction<any>>;
    loading: boolean;

    onCancel: () => void;
    onSubmit: () => void;
}

export const SaveCancelSection = (props: IEditableSave) => {
    function cancel() {
        props.setEditMode(false);
        props.onCancel();
    }

    function submit() {
        props.onSubmit();
    }

    return (
        <main
            id="full-width-bottom"
            className={"bottom " + (props.editMode ? "active" : "")}
        >
            <div className="container">
                <div className="row justify-content-md-center text-center">
                    <div>
                        <FormItem
                            id={"save"}
                            label={"Save"}
                            inputType={"submit"}
                            required={true}
                            onChange={submit}
                            initialValue={"Save"}
                            loading={props.loading}
                        />
                        <FormItem
                            id={"cancel"}
                            label={"Cancel"}
                            inputType={"submit"}
                            required={true}
                            onChange={cancel}
                            initialValue={"Cancel"}
                        />
                    </div>
                </div>
            </div>
        </main>
    );
};
