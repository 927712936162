import BaseObject from "../BaseObject";

export default class UserList extends BaseObject {
    formattedUsers: IFormattedUser[];

    constructor(formattedUsers: IFormattedUser[]) {
        super();
        this.formattedUsers = formattedUsers;
    }
}

export interface IFormattedUser {
    username: string;
    userType: string;
    dateCreated: string;
    salesforceId: string;
}