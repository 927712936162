import {UserCache} from "./UserCache";

class TokenUserCache {
    tokenUser: Map<string, UserCache>;

    constructor() {
        this.tokenUser = new Map<string, UserCache>();
    }

    public getUserCache(token: string) {
        let user = this.tokenUser.get(token);
        if (user === undefined) {
            user = new UserCache(token);
            this.tokenUser.set(token, user);
        }
        user.lastAccessed = new Date();

        return user;
    }

    public invalidateCache(token: string[]) {
        token.forEach((value) => this.tokenUser.delete(value));
    }
}

const tokenUserCache = new TokenUserCache();
export default tokenUserCache;