import BaseObject from "../BaseObject";

export default class TaskList extends BaseObject {
    stageTeams: IStageTeam[];

    constructor(stageTeams: IStageTeam[]) {
        super();
        this.stageTeams = stageTeams;
    }
}

export interface IStageTeam {
    teamName: string;
    stageForm: IStageForm[];
}

interface IStageForm {
    stage: IViewableStage;
    form: IViewableForm[];
}

export interface IViewableStage {
    name: string;
}

export interface IViewableForm {
    id: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    deadlineToday: boolean;
    passedDeadline: boolean;
    repeatable: boolean;
    formId: string;
    completed: boolean;
}

