import {useEffect, useState} from "react";
import "./table.css";
import {ITableRow} from "./TableRow";

interface ITable {
    head: string[];
    children: JSX.Element | JSX.Element[];
    download?: boolean;
}

export const Table = (props: ITable) => {
    const [tableHead, setTableHead] = useState<JSX.Element[] | undefined>(
        undefined
    );

    useEffect(() => {
        let builtHead: JSX.Element[] = [];
        props.head.forEach((value, index) =>
            builtHead.push(
                <th
                    key={value + index}
                    scope={"col"}
                    rowSpan={2}
                    className={"table-no-border"}
                >
                    {value}
                </th>
            )
        );
        setTableHead(builtHead);
    }, [props.head]);

    function downloadButton() {
        let csv = '';

        //Add the head
        props.head.forEach(value => {
            csv += value + ",";
        });
        csv += '\r\n'; //new line
        //Add the head

        //Add all the other elements
        if (!Array.isArray(props.children)) {
            let item = props.children.props as ITableRow;
            csv += convertRowToCsv(item);
        } else {
            props.children.forEach(row => {
                csv += convertRowToCsv(row.props as ITableRow);
            })
        }
        //Add all the other elements

        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'download.csv';
        hiddenElement.click();
    }

    function convertRowToCsv(tableRow: ITableRow): string {
        let csv = '';
        if (Array.isArray(tableRow.children)) {
            tableRow.children.forEach((value: any) => {
                csv += convertTableDataToCsv(value.props);
            })
        } else {
            csv += convertTableDataToCsv(tableRow);
        }

        return csv + "\r\n";
    }

    function convertTableDataToCsv(item: any, skipDelimiter?: boolean) {
        let csv = '';
        if (!item.url) item = item.element ?? item;

        if (typeof item === "string") {
            csv += item;
        } else if (item.url) {
            csv += item.url;
        } else if (item.type === "i") {
            let className = item.props.className;
            if (className === "far fa-square text-black-50") csv += "No";
            else if (className === "far fa-check-square text-black-50") csv += "Yes";
        } else if (item.type === "a") {
            csv += item.props.href;
        } else if (item.type === "br") {
            csv += '\r\n';
        } else if (Array.isArray(item)) {
            csv += "\"";
            item.forEach((value: any) => {
                csv += convertTableDataToCsv(value, true);
            });
            csv += "\"";
        }

        if (!skipDelimiter) csv += ","
        return csv;
    }

    return (
        <>
            {props.download ?
                <button className={"download-button"} onClick={downloadButton}>Download</button> : undefined}
            <table>
                <thead>
                <tr>{tableHead}</tr>
                </thead>

                <tbody>{props.children}</tbody>
            </table>
        </>
    );
};
