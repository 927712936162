import AdminWrapper from "../AdminWrapper";
import GeneralResponse from "../../../GeneralResponse";

class DeleteArticle extends AdminWrapper {
    async request(id: string, token: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/article/${encodeURIComponent(id)}`,
            "DELETE",
            token,
            undefined
        );
    }
}

export default new DeleteArticle();