import TeamWrapper from "../TeamWrapper";
import GeneralResponse from "../../../GeneralResponse";

class AssignMentor extends TeamWrapper {
    async request(
        code: string,
        mentor: string,
        token: string
    ): Promise<GeneralResponse> {
        return await this.formattedRequest("/assign/mentor", "POST", token, {
            code: code,
            mentor: mentor,
        });
    }
}

export default new AssignMentor();