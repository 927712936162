import BaseObject from "../../BaseObject";

export default class Sidebar extends BaseObject {
    username: string;
    sidebar: IViewableSidebarItem[];

    constructor(username: string, sidebar: IViewableSidebarItem[]) {
        super();
        this.username = username;
        this.sidebar = sidebar;
    }
}

export interface IViewableSidebarItem {
    name: string;
    icon: string;
    url: string;
    external: boolean;
}