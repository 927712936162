import React, {Dispatch, SetStateAction} from "react";
import {FormItem} from "../../../form/FormItem";

interface IInformation {
    subtitle: string | undefined | JSX.Element;
    information: string | undefined | JSX.Element;
    onEditInformation?: string;
    studentInformation?: JSX.Element;

    onChange?: Dispatch<SetStateAction<any>>;
    dataType?:
        | "option"
        | "text"
        | "password"
        | "tel"
        | "email"
        | "date"
        | "number"
        | "radio"
        | "textarea"
        | "submit";
    editMode?: boolean;
}

export const Information = (props: IInformation) => {
    let editable = (props.dataType !== undefined) !== undefined;

    return (
        <>
            {props.subtitle && (
                <span className={"row-subtitle"}>{props.subtitle}</span>
            )}
            {props.studentInformation === undefined && props.subtitle && <br/>}

            {(!editable || !props.editMode) && (
                <span className={"row-information"}>{props.information}</span>
            )}
            {props.editMode && editable && (
                <FormItem
                    id={props.subtitle as string}
                    label={undefined}
                    inputType={props.dataType as any}
                    required={true}
                    onChange={props.onChange as Dispatch<SetStateAction<any>>}
                    initialValue={props.onEditInformation ?? props.information as string}
                />
            )}

            {props.studentInformation !== undefined && (
                <div className={"student-information"}>{props.studentInformation}</div>
            )}
        </>
    );
};