import BaseObject from "../../BaseObject";

export default class ArticleList extends BaseObject {
    programmeArticleList: Map<string, IChapterArticles[]>;

    constructor(programmeArticleList: any) {
        super();
        this.programmeArticleList = new Map<string, IChapterArticles[]>();


        let keys = (Object.keys(programmeArticleList));
        keys.forEach(key => {
            let chapterArticles: IChapterArticles[] = programmeArticleList[key];
            this.programmeArticleList.set(key, chapterArticles);
        });

    }
}

export interface IChapterArticles {
    name: string;
    order: number;
    blurred: boolean;
    articles: IArticle[];
}

export interface IArticle {
    name: string;
    articleId: string;
    salesforceId: string;
    order: number;
    readingTime: number;
    snippet: string;
    read: boolean;
    locked: boolean;
}