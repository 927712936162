import {FormPage} from "../../../elements/form/FormPage";
import {FormItem} from "../../../elements/form/FormItem";
import {useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {default as AssignStudentWrapper} from "../../../backend/wrapper/team/assign/AssignStudent";

import {useNavigate} from "react-router-dom";

export const AssignStudent = () => {
    const [token, setToken] = useState("");
    const [role, setRole] = useState("");
    const navigate = useNavigate();

    async function validateData(): Promise<string | undefined> {
        return undefined;
    }

    async function submit(): Promise<string | undefined> {
        let teamCode: string | undefined = undefined;
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        //Get the team code from the slider
        let teams = await userCache.teams;

        if (teams === undefined) return;

        if (sessionStorage.getItem("teams-team-name") !== undefined)
            teamCode = teams?.teams?.filter(
                (value) =>
                    value.companyName === sessionStorage.getItem("teams-team-name")
            )[0]?.teamCode;
        if (teamCode === undefined) teamCode = teams?.teams[0]?.teamCode;

        if (teamCode === undefined)
            return "Unable to get team.  Please refresh the page or contact JA Malta";
        //Get the team code from the slider

        let request = await AssignStudentWrapper.request(
            token,
            role,
            teamCode,
            userCache.token
        );
        if (request.status !== 200) return request.text;
        else {
            TokenUserCache.invalidateCache([userCache.token]);
            navigate("../team");
        }
    }

    return (
        <main id={"small-box"} className={"right-blue-border"}>
            <FormPage
                title={"ASSIGN STUDENT"}
                currentState={"submit"}
                validateData={validateData}
                submit={submit}
            >
                <FormItem
                    id={"role"}
                    label={"Role"}
                    inputType={"text"}
                    required={true}
                    onChange={setRole}
                    initialValue={""}
                />
                <FormItem
                    id={"token"}
                    label={"Token"}
                    inputType={"text"}
                    required={true}
                    onChange={setToken}
                    initialValue={""}
                />
            </FormPage>
        </main>
    );
};
