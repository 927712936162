import React, {Dispatch, SetStateAction} from "react";

interface IEditButton {
    setEditMode?: Dispatch<SetStateAction<boolean>>;
}

export const EditButton = (props: IEditButton) => {
    function edit() {
        if (props.setEditMode) props.setEditMode(true);
    }

    return (
        <div className={"edit"}>
            <button onClick={(event) => edit()}>
                <i className={"far fa-edit"}/>
            </button>
        </div>
    );
};