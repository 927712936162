import React, {useState} from "react";
import {FormPage} from "../../../elements/form/FormPage";
import {FormItem} from "../../../elements/form/FormItem";
import {default as CreateTeacherWrapper} from "../../../backend/wrapper/admin/create/CreateTeacher";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {GenericBox} from "../../../elements/GenericBox";

export const CreateTeacher = () => {
    const [idCard, setIdCard] = useState("");
    const [showBox, setShowBox] = useState(false);

    async function validateData(): Promise<string | undefined> {
        return undefined;
    }

    async function submit(): Promise<string | undefined> {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        let request = await CreateTeacherWrapper.request(idCard, userCache.token);
        if (request.status !== 201) return request.text;
        setShowBox(true);
    }

    return (
        <>
            {!showBox ? (
                <main id={"small-box"} className={"right-blue-border"}>
                    <FormPage
                        title={"Create Teacher Account"}
                        currentState={"submit"}
                        validateData={validateData}
                        submit={submit}
                    >
                        <FormItem
                            id={"id-card"}
                            label={"ID Card"}
                            inputType={"text"}
                            required={true}
                            onChange={setIdCard}
                            initialValue={""}
                        />
                    </FormPage>
                </main>
            ) : (
                <GenericBox
                    title={"Teacher Created"}
                    text={
                        "The user has been successfully created.  The respective link teacher should have received an email with the username and temporary password.  Please follow up with link teacher to confirm that they have received and seen the email."
                    }
                    image={"/image/authentication/registration-complete.svg"}
                />
            )}
        </>
    );
};
