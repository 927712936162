import React, {useState} from "react";
import {FormPage} from "../../../elements/form/FormPage";
import {FormItem} from "../../../elements/form/FormItem";
import {default as CreateAdminWrapper} from "../../../backend/wrapper/admin/create/CreateAdmin";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {GenericBox} from "../../../elements/GenericBox";

export const CreateAdmin = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [showBox, setShowBox] = useState(false);

    async function validateData(): Promise<string | undefined> {
        if (password.trim() !== confirmPassword.trim())
            return "The passwords entered do not match.";
        return undefined;
    }

    async function submit(): Promise<string | undefined> {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        let request = await CreateAdminWrapper.request(
            username,
            password,
            userCache.token
        );
        if (request.status !== 201) return request.text;
        setShowBox(true);
    }

    return (
        <>
            {!showBox ? (
                <main id={"small-box"} className={"right-blue-border"}>
                    <FormPage
                        title={"Create Admin Account"}
                        currentState={"submit"}
                        validateData={validateData}
                        submit={submit}
                    >
                        <FormItem
                            id={"username"}
                            label={"Username"}
                            inputType={"text"}
                            required={true}
                            onChange={setUsername}
                            initialValue={""}
                        />
                        <FormItem
                            id={"new-password"}
                            label={"New Password"}
                            inputType={"password"}
                            required={true}
                            onChange={setPassword}
                            initialValue={""}
                        />
                        <FormItem
                            id={"confirm-new-password"}
                            label={"Confirm New Password"}
                            inputType={"password"}
                            required={true}
                            onChange={setConfirmPassword}
                            initialValue={""}
                        />
                    </FormPage>
                </main>
            ) : (
                <GenericBox
                    title={"Admin Created"}
                    text={
                        "The admin user has been created.  Please advice the admin to change their password when logging in for the first time."
                    }
                    image={"/image/authentication/registration-complete.svg"}
                />
            )}
        </>
    );
};
