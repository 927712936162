import React, {useState} from "react";
import CreateForgotToken from "../../../backend/wrapper/auth/forgot/CreateForgotToken";
import {GenericBox} from "../../../elements/GenericBox";
import {FormPage} from "../../../elements/form/FormPage";
import {FormItem} from "../../../elements/form/FormItem";

export const Forgot = () => {
    const [username, setUsername] = useState("");
    const [showBox, setShowBox] = useState(false);

    async function validateData(): Promise<string | undefined> {
        return undefined;
    }

    async function submit(): Promise<string | undefined> {
        const createReset = await CreateForgotToken.request(username);
        if (createReset.status !== 201) return createReset.text;
        else setShowBox(true);
    }

    return (
        <>
            {!showBox ? (
                <main id={"small-box"} className={"right-blue-border"}>
                    <FormPage
                        title={"FORGOT"}
                        currentState={"submit"}
                        validateData={validateData}
                        submit={submit}
                    >
                        <FormItem
                            id={"username"}
                            label={"Username"}
                            inputType={"text"}
                            required={true}
                            onChange={setUsername}
                            initialValue={""}
                        />
                    </FormPage>
                </main>
            ) : (
                <GenericBox
                    title={"Request Sent"}
                    text={
                        "A request to change your password has been created and each team member should get an email with a link.  Please note that the link can only be used once by only one team member."
                    }
                    image={"/image/authentication/registration-complete.svg"}
                />
            )}
        </>
    );
};
