import React, {ReactElement, useEffect, useState} from "react";
import {Dashboard} from "../../../elements/dashboard/Dashboard";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {Event} from "../../../elements/dashboard/events/Event";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import {EventCategory} from "../../../elements/dashboard/events/EventCategory";

export const EventList = () => {
    const [containers, setContainers] = useState<ReactElement[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(localStorage.getItem("token") as string);

        let containers : ReactElement[] = [];
        userCache.eventList.then(events => {
            if(events?.events && events.events.length < 1) return;

            events?.events.forEach((events, index) => {
                let upcomingEvents : ReactElement[] = [];
                let passedEvents : ReactElement[] = [];

                events.events.filter(value => value.status === "Upcoming").forEach(event => {upcomingEvents.push(<Event {...event} key={event.name}/>)});
                events.events.filter(value => value.status !== "Upcoming").forEach(event => {passedEvents.push(<Event {...event} key={event.name}/>)});

                let passedReportedEvents = (events.events.filter(value => (value.status !== "Upcoming" && value.status !== "Pending Reporting")));
                let attended = passedReportedEvents.filter(value => value.status === "Attended");

                let attendedCalculation = 0;
                if(attended.length !== 0 && passedReportedEvents.length !== 0) attendedCalculation = Math.round ((attended.length / passedReportedEvents.length) * 100);

                containers.push(
                    <FullInformation key={events.programmeName} active={index === activeTab}>
                        <EventCategory title={"Upcoming Events"} key={`upcoming-${events.programmeName}`}>
                            {upcomingEvents}
                        </EventCategory>

                        <EventCategory title={"Passed Events"} calculation={String(attendedCalculation)} key={`passed-${events.programmeName}`}>
                            {passedEvents}
                        </EventCategory>
                    </FullInformation>);
            });
            setContainers(containers);
        });
    });

    function slickChange(currentSlide: number, nextSlide: number) {
        sessionStorage.setItem("slick-tab-content", String(nextSlide));
        sessionStorage.setItem("events-programme", containers[nextSlide]?.props.name);
        setActiveTab(nextSlide);
    }

    return (
        <Dashboard
            title={"Events"}
            className={"tasks"}
            slickChange={slickChange}
            sliderKey={"content"}
        >

            {containers}
        </Dashboard>
    )
}