import AdminWrapper from "./AdminWrapper";
import GeneralResponse from "../../GeneralResponse";

class DeleteUser extends AdminWrapper {
    async request(username: string, token: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/delete-user/${encodeURIComponent(username)}`,
            "DELETE",
            token,
            undefined
        );
    }
}

export default new DeleteUser();