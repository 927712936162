import UserWrapper from "../UserWrapper";

class ChangePassword extends UserWrapper {
    async request(
        oldPassword: string,
        newPassword: string,
        token: string
    ): Promise<any> {
        return await this.formattedRequest("/change/password", "PATCH", token, {
            oldPassword: oldPassword,
            newPassword: newPassword,
        });
    }
}

export default new ChangePassword();