import BaseObject from "../../BaseObject";

export default class Notification extends BaseObject implements INotification {
    message: string;
    title: string;
    startDate: number;


    constructor(notification: INotification) {
        super();
        this.title = notification.title;
        this.message = notification.message;
        this.startDate = notification.startDate;
    }
}

export interface INotification {
    title: string;
    message: string;
    startDate: number;
}