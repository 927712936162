import AdminWrapper from "./AdminWrapper";
import UserList, {IFormattedUser} from "../../model/admin/UserList";
import DetailedResponse from "../../DetailedResponse";

class GetUserList extends AdminWrapper {
    async request(token: string): Promise<DetailedResponse<UserList>> {
        let request = await this.detailedFormattedRequest<IFormattedUser[]>(
            "/list-users",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        return new DetailedResponse<UserList>(
            request.status,
            request.text,
            new UserList(request.additionalInformation)
        );
    }
}

export default new GetUserList();