import {FormPage} from "../../../elements/form/FormPage";
import {FormItem} from "../../../elements/form/FormItem";
import {useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import CreateArticleWrapper from "../../../backend/wrapper/admin/article/CreateArticle";
import {useNavigate} from "react-router-dom";


export const CreateArticle = () => {
    const [name, setName] = useState("");
    const navigate = useNavigate();

    async function validateData(): Promise<string | undefined> {
        return undefined;
    }

    async function submit(): Promise<string | undefined> {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        let request = await CreateArticleWrapper.request(
            name,
            userCache.token
        );
        if (request.status !== 200) return request.text;
        TokenUserCache.invalidateCache([userCache.token]);

        navigate("/admin/article/list")
        return undefined;
    }

    return (
        <>
            <main id={"small-box"} className={"right-blue-border"}>
                <FormPage title={"Create Article"} currentState={"submit"} validateData={validateData} submit={submit}>
                    <FormItem id={"name"} label={"Article Name"} inputType={"text"} required={true}
                              onChange={setName} initialValue={""}/>
                </FormPage>
            </main>
        </>
    )
}