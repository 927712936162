import React, {useState} from "react";
import {GenericBox} from "../../../elements/GenericBox";
import TokenUserCache from "../../../backend/cache/TokenUserCache";

export const Password = () => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [oldPassword, setOldPassword] = useState<string | undefined>(undefined);
    const [newPassword, setNewPassword] = useState<string | undefined>(undefined);
    const [confirmNewPassword, setConfirmNewPassword] = useState<string | undefined>(undefined);
    const [redirect, setRedirect] = useState(false);

    function handleSubmit(e: any) {
        e.preventDefault();
        if (newPassword !== confirmNewPassword)
            return setError("The passwords entered do not match.");
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache
            .updatePassword(oldPassword as string, newPassword as string)
            .then((value) => {
                if (value.status !== 200) setError(value.text);
                else {
                    window.localStorage.clear(); //Reset the local storage and session storage
                    window.sessionStorage.clear();
                    setRedirect(true);
                }
            });
    }

    if (redirect)
        return (
            <GenericBox
                title={"Password Changed"}
                text={"Password has been changed successfully. You are now logged-out."}
                image={"/image/authentication/registration-complete.svg"}
            />
        );

    return (
        <main id={"small-box"} className={"right-blue-border"}>
            <form onSubmit={handleSubmit}>
                <h1>CHANGE PASSWORD</h1>
                <div className={"error-field"}>{error}</div>

                <label htmlFor="old-password">Old Password</label>
                <input
                    type="password"
                    id="old-password"
                    name="old-password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                />

                <label htmlFor="new-password">New Password</label>
                <input
                    type="password"
                    id="new-password"
                    name="new-password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />

                <label htmlFor="confirm-new-password">Confirm New Password</label>
                <input
                    type="password"
                    id="confirm-new-password"
                    name="confirm-new-password"
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    required
                />

                <input type="submit" value="Submit"/>
            </form>
        </main>
    );
};
