import UserWrapper from "../UserWrapper";
import LeadList, {ILead} from "../../../model/user/supervisor/LeadList";
import DetailedResponse from "../../../DetailedResponse";

class GetLeadList extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<LeadList>> {
        let request = await this.detailedFormattedRequest<ILead[]>(
            "/supervisor/list/leads",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        let leads = new LeadList(request.additionalInformation);
        return new DetailedResponse<LeadList>(request.status, request.text, leads);
    }
}

export default new GetLeadList();