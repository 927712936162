import {useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {Playlist} from "../../../elements/dashboard/videos/Playlist";

export const VideoTutorials = () => {
    const [playlist, setPlaylists] = useState<JSX.Element[]>([]);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        userCache.videoPlaylists.then((videoPlaylistsRequest) => {
            let playlists: JSX.Element[] = [];
            videoPlaylistsRequest?.videoPlaylists.forEach((value) =>
                playlists.push(
                    <Playlist
                        key={value.name}
                        name={value.name}
                        logoUrl={value.logoUrl}
                        videos={value.videos}
                    />
                )
            );
            setPlaylists(playlists);
        });
    }, []);

    return (
        <Dashboard
            title={"Video Tutorials"}
            className={"video-tutorials"}
            key={"videos"}
        >
            {playlist}
        </Dashboard>
    );
};
