import React, {useState} from "react";
import {GenericBox} from "../../../elements/GenericBox";
import TokenUserCache from "../../../backend/cache/TokenUserCache";

export const Username = () => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [redirect, setRedirect] = useState(false);

    function handleSubmit(e: any) {
        e.preventDefault();
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.updateUsername(username as string).then((value) => {
            if (value.status !== 200) setError(value.text);
            else setRedirect(true);
        });
    }

    if (redirect)
        return (
            <GenericBox
                title={"Username Changed"}
                text={"Username changed successfully."}
                image={"/"}
            />
        );

    return (
        <main id={"small-box"} className={"right-blue-border"}>
            <form onSubmit={handleSubmit}>
                <h1>CHANGE USERNAME</h1>
                <div className={"error-field"}>{error}</div>

                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />

                <input type="submit" value="Submit"/>
            </form>
        </main>
    );
};
