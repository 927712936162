import React, {Dispatch, SetStateAction, useEffect, useState} from "react";

export interface IFormItem {
    id: string;
    label: string | undefined;
    inputType:
        | "option"
        | "text"
        | "password"
        | "tel"
        | "email"
        | "date"
        | "number"
        | "radio"
        | "textarea"
        | "submit"
        | "file";
    required: boolean;
    onChange: Dispatch<SetStateAction<any>>;
    initialValue: string | number;
    options?: string[];
    radio?: { label: string; value: string }[];
    accept?: string;
    multiple?: boolean;
    loading?: boolean;
    cloned?: boolean;
    disabled?: boolean;
}

export const FormItem = (props: IFormItem) => {
    const [options, setOptions] = useState<JSX.Element[]>([]);
    const [disabled, setDisabled] = useState(false);

    function onOptionClick() {
        setDisabled(true);
    }

    useEffect(() => {
        if (
            props.inputType === "option" &&
            props.options !== undefined &&
            props.options.length > 0
        ) {
            let compiledOptions: JSX.Element[] = [];
            compiledOptions.push(
                <option
                    disabled={disabled}
                    label={String(props.initialValue)}
                    key={"initial-value"}
                >
                    {props.initialValue}
                </option>
            );
            props.options.forEach((value) =>
                compiledOptions.push(
                    <option value={value} key={value}>
                        {value}
                    </option>
                )
            );
            setOptions(compiledOptions);
        } else if (
            props.inputType === "radio" &&
            props.radio !== undefined &&
            props.radio.length > 0
        ) {
            let compiledOptions: JSX.Element[] = [];
            props.radio.forEach((value) =>
                compiledOptions.push(
                    (
                        <input
                            type={"radio"}
                            id={props.id}
                            name={props.id}
                            value={String(value.value)}
                            key={value.label}
                            required={props.required}
                            data-label={value.label}
                            onChange={(event) => props.onChange(event.target.value)}
                        />
                    ) as any
                )
            );
            setOptions(compiledOptions);
        }
    }, [disabled, props]);

    useEffect(() => {
        if (props.cloned) props.onChange(props.initialValue);
    }, [props]);

    switch (props.inputType) {
        case "option":
            if (props.options === undefined || props.options.length <= 0)
                return <span>Invalid form item provided</span>;
            return (
                <>
                    {props.label !== undefined && (
                        <label htmlFor={props.id}>{props.label}</label>
                    )}
                    <select
                        name={props.id}
                        id={props.id}
                        required={props.required}
                        onClick={onOptionClick}
                        onChange={(event) => props.onChange(event.target.value)}
                        disabled={props.disabled}
                    >
                        {options}
                    </select>
                </>
            );
        case "text":
        case "number":
        case "password":
        case "tel":
        case "email":
        case "date":
            return (
                <>
                    {props.label !== undefined && (
                        <label htmlFor={props.id}>{props.label}</label>
                    )}
                    <input
                        type={props.inputType}
                        id={props.id}
                        name={props.id}
                        defaultValue={props.initialValue}
                        required={props.required}
                        onChange={(event) => props.onChange(event.target.value)}
                        disabled={props.disabled}
                    />
                </>
            );
        case "radio":
            return (
                <>
                    {props.label !== undefined && (
                        <label htmlFor={props.id}>{props.label}</label>
                    )}
                    {options}
                </>
            );
        case "textarea":
            return (
                <>
                    {props.label !== undefined && (
                        <label htmlFor={props.id}>{props.label}</label>
                    )}
                    <textarea
                        id={props.id}
                        name={props.id}
                        required={props.required}
                        rows={5}
                        defaultValue={props.initialValue}
                        onChange={(event) => props.onChange(event.target.value)}
                        disabled={props.disabled}
                    />
                </>
            );
        case "submit":
            return (
                <>
                    <button
                        id={props.id}
                        disabled={props.loading}
                        type={props.inputType}
                        onClick={(event) => props.onChange(event)}
                    >
                        {props.label}&nbsp;
                        <div className={"loading " + (props.loading ? "active " : "")}>
                            <i className="fas fa-spinner fa-pulse"/>
                        </div>
                    </button>
                </>
            );
        case "file":
            return (
                <>
                    {props.label !== undefined && (
                        <label htmlFor={props.id}>{props.label}</label>
                    )}
                    <input
                        type={props.inputType}
                        id={props.id}
                        name={props.id}
                        accept={props.accept}
                        defaultValue={props.initialValue}
                        required={props.required}
                        multiple={props.multiple}
                        onChange={(event) => props.onChange(event.target.files)}
                        disabled={props.disabled}
                    />
                </>
            );
        default:
            return <i/>;
    }
};