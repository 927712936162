import React, {useEffect, useState} from "react";
import {FormItem} from "./FormItem";
import "./form.css";

export interface IFormPage {
    title: string;
    children: JSX.Element[] | JSX.Element;
    currentState: "next" | "previous" | "submit";

    dependent?: boolean;
    repeatAmount?: number;
    onRepeat?: (counter: number) => void;

    validateData: () => Promise<string | undefined>;
    next?: any;
    submit?: () => Promise<string | undefined>;
    submitAndRepeat?: () => Promise<string | undefined>;
}

export const FormPage = (props: IFormPage) => {
    const [amountRepeated, setAmountRepeated] = useState(0);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [children, setChildren] = useState<JSX.Element | JSX.Element[] | undefined>(undefined);

    useEffect(() => {
        if (props.dependent !== undefined) {
            if (props.currentState === "next" && !props.dependent)
                nextPage(undefined);
        } else if (props.repeatAmount !== undefined && props.onRepeat) {
            if (props.repeatAmount === 0 && props.currentState === "next")
                nextPage(undefined);
        }
    }, [
        props.currentState,
        props.dependent,
        props.repeatAmount,
        amountRepeated,
        props.onRepeat,
    ]);

    useEffect(() => {
        const paramsArray = new URL(document.location.toString()).search
            .substring(1)
            .split("&")
            .map((value) => value.split("="));
        const params = new Map(paramsArray.map(([value, key]) => [value, key]));

        if (Array.isArray(props.children)) {
            let editableChildren = [];

            for (let i = 0; i !== props.children.length; i++) {
                let currentItem = props.children[i];
                if (params.get(currentItem.props.id) !== undefined)
                    editableChildren.push(
                        React.cloneElement(currentItem, {
                            initialValue: decodeURIComponent(
                                params.get(currentItem.props.id) as string
                            ),
                            key: currentItem.props.id,
                            cloned: true,
                        })
                    );
                else editableChildren.push(currentItem);
            }

            setChildren(editableChildren);
        } else if (
            !Array.isArray(props.children) &&
            params.get(props.children.props.id)
        )
            setChildren(
                React.cloneElement(props.children, {
                    initialValue: params.get(props.children.props.id),
                    key: props.children.props.id,
                })
            );
        else setChildren(props.children);
    }, [props.children]);

    function nextPage(event: any) {
        if (event) event.preventDefault();
        setLoading(true);

        if (!event) return props.next();

        props.validateData().then((value) => {
            if (value !== undefined) {
                setLoading(false);
                return setError(value);
            } else setError(""); //Clear any previous errors

            if (props.repeatAmount && props.onRepeat) {
                setAmountRepeated(amountRepeated + 1);
                if (amountRepeated < props.repeatAmount - 1) {
                    setLoading(false);
                    return props.onRepeat(amountRepeated);
                } else if (amountRepeated === props.repeatAmount - 1)
                    props.onRepeat(amountRepeated);
            }

            setLoading(false);
            props.next();
        });
    }

    function submit(event: any, repeat?: boolean) {
        if (event) event.preventDefault();
        setLoading(true);

        props.validateData().then((value) => {
            if (value !== undefined) {
                setLoading(false);
                return setError(value);
            } else setError(""); //Clear any previous errors

            if (!props.submit) {
                setLoading(false);
                return;
            }

            let submitFunction = (repeat && props.submitAndRepeat !== undefined) ? props.submitAndRepeat : props.submit;

            submitFunction().then((submitResponse) => {
                if (submitResponse !== undefined) {
                    setLoading(false);
                    setError(submitResponse);
                }
            });
        });
    }

    return (
        <form>
            {props.onRepeat && (
                <h1>
                    {props.title} {amountRepeated + 1}
                </h1>
            )}
            {!props.onRepeat && <h1>{props.title}</h1>}
            <br/>
            <div className={"error-field"}>{error}</div>
            {children}
            <div className={"spacer"}/>
            {props.next && (
                <FormItem
                    id={"submit"}
                    label={"Next"}
                    inputType={"submit"}
                    required={true}
                    onChange={nextPage}
                    loading={loading}
                    initialValue={""}
                />
            )}

            {props.submitAndRepeat && (
                <FormItem
                    id={"submit-repeat"}
                    label={"Submit & Create New"}
                    inputType={"submit"}
                    required={true}
                    onChange={(event) => submit(event, true)}
                    loading={loading}
                    initialValue={""}
                />
            )}

            {props.submit && (
                <FormItem
                    id={"submit"}
                    label={"Submit"}
                    inputType={"submit"}
                    required={true}
                    onChange={(event) => submit(event, false)}
                    loading={loading}
                    initialValue={""}
                />
            )}
        </form>
    );
};