import BaseObject from "../../BaseObject";

export default class LeadList extends BaseObject {
    leads: ILead[];

    constructor(leads: ILead[]) {
        super();
        this.leads = leads;
    }
}

export interface ILead {
    name: string;
    email: string;
    idCard: string;
    consentRequested: boolean;
    consentUploaded: boolean | string;
    paymentReceived: boolean | string;
    internalStatus: string;
}