import UserWrapper from "../UserWrapper";
import DetailedResponse from "../../../DetailedResponse";
import {IArticle} from "../../../model/user/content/Article";

class GetArticle extends UserWrapper {
    async request(id: string, token: string): Promise<DetailedResponse<IArticle>> {
        return await this.detailedFormattedRequest<IArticle>(
            `/article/${encodeURIComponent(id)}`,
            "GET",
            token,
            undefined
        );
    }
}

export default (new GetArticle());