import AdminWrapper from "../AdminWrapper";
import GeneralResponse from "../../../GeneralResponse";

class RenameArticle extends AdminWrapper {
    async request(id: string, name: string, token: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/article/${encodeURIComponent(id)}/rename`,
            "POST",
            token,
            {name: name}
        );
    }

}

export default new RenameArticle();