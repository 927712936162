import AuthWrapper from "../AuthWrapper";
import GeneralResponse from "../../../GeneralResponse";

class CreateForgotToken extends AuthWrapper {
    async request(username: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/forgot/${encodeURIComponent(username)}`,
            "GET",
            undefined,
            undefined
        );
    }
}

export default new CreateForgotToken();