import UserWrapper from "../UserWrapper";
import GeneralResponse from "../../../GeneralResponse";

class ChangeUsername extends UserWrapper {
    async request(username: string, token: string): Promise<GeneralResponse> {
        return await this.formattedRequest("/change/username", "PATCH", token, {
            username: username,
        });
    }
}

export default new ChangeUsername();