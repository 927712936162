import BaseObject from "../BaseObject";
import {IPublicContactInformation} from "../user/User";

export default class Teams extends BaseObject {
    teams: ITeam[];

    constructor(teams: ITeam[]) {
        super();
        this.teams = teams;
    }
}

export interface ITeam {
    companyName: string;
    abbreviatedName: string;
    mission: string;
    productName: string;
    productDescription: string;
    logo: string;

    teamCode: string;
    programme: string;

    totalShareCapitalIssued: string;
    vatFinanceTracker: string;

    studentInformation: IContactRole[];
    mentorInformation: IPublicContactInformation[];
    linkTeacherInformation: IPublicContactInformation[];
}

interface IContactRole{
    contact: IPublicContactInformation;
    role: string;
}