import React, {Dispatch, SetStateAction} from "react";
import {SaveCancelSection} from "./SaveCancelSection";
import {Loading} from "../Loading";

interface IDashboardInformation {
    children: React.ReactNode[] | React.ReactNode;
    key: React.Key;
    active: boolean;
    name?: string;

    loading?: boolean;
    editMode?: boolean;
    setEditMode?: Dispatch<SetStateAction<any>>;
    onCancel?: () => void;
    onSubmit?: () => void;
}

export const FullInformation = (props: IDashboardInformation) => {
    return (
        <section className={"full-information " + (props.active ? "active" : " ")}>
            {(props.children === undefined ||
                (Array.isArray(props.children) && props.children.length === 0)) && (
                <Loading/>
            )}
            {props.children}

            {props.onSubmit && props.onCancel && props.loading !== undefined && (
                <SaveCancelSection
                    editMode={props.editMode}
                    setEditMode={props.setEditMode as any}
                    onCancel={props.onCancel}
                    onSubmit={props.onSubmit}
                    loading={props.loading}
                />
            )}
        </section>
    );
};