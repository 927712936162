import React, {useState} from "react";
import {FormPage} from "../../elements/form/FormPage";
import {FormItem} from "../../elements/form/FormItem";
import TokenUserCache from "../../backend/cache/TokenUserCache";
import {GenericBox} from "../../elements/GenericBox";
import {default as ResetYearlyWrapper} from "../../backend/wrapper/admin/ResetYearly";

export const ResetYearly = () => {
    const [programme, setProgramme] = useState("");
    const [showBox, setShowBox] = useState(false);

    async function validateData(): Promise<string | undefined> {
        return undefined;
    }

    async function submit(): Promise<string | undefined> {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        let request = await ResetYearlyWrapper.request(programme, userCache.token);
        if (request.status !== 200) return request.text;
        setShowBox(true);

        TokenUserCache.invalidateCache([userCache.token]);
    }

    return (
        <>
            {!showBox ? (
                <main id={"small-box"} className={"right-blue-border"}>
                    <FormPage
                        title={"Reset Student Accounts"}
                        currentState={"submit"}
                        validateData={validateData}
                        submit={submit}
                    >
                        <FormItem
                            id={"programme"}
                            label={"Programme Detail Code"}
                            inputType={"text"}
                            required={true}
                            onChange={setProgramme}
                            initialValue={""}
                        />
                    </FormPage>
                </main>
            ) : (
                <GenericBox
                    title={"Programme Reset"}
                    text={"All students of the programme have been deleted successfully."}
                    image={"/image/authentication/registration-complete.svg"}
                />
            )}
        </>
    );
};
