import UserWrapper from "../UserWrapper";
import DetailedResponse from "../../../DetailedResponse";
import NotificationBell from "../../../model/user/viewables/NotificationBell";

class GetNotificationBell extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<NotificationBell>> {
        let request = await this.formattedRequest(
            "/viewables/notifications",
            "GET",
            token,
            undefined
        );

        if (request.additionalInformation && Object.keys(request.additionalInformation).length === 0) request.additionalInformation = []

        if (request.status !== 200) return request as any;
        let viewable = new NotificationBell(request.additionalInformation);
        return new DetailedResponse<NotificationBell>(200, request.text, viewable);
    }
}

export default new GetNotificationBell();
