import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import {Table} from "../../../elements/table/Table";
import {TableRow} from "../../../elements/table/TableRow";
import {TableData} from "../../../elements/table/TableData";
import {Link} from "react-router-dom";

export const ShareRegistryList = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [containers, setContainers] = useState<JSX.Element[]>([]);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.shareRegistry.then((value) => {
            if (value === undefined) return;
            let teamShareRegistry = value.shareRegistryInformation;

            let containers: JSX.Element[] = [];

            teamShareRegistry.forEach((teamRegistry, index) => {
                let shareholders: JSX.Element[] = [];

                teamRegistry.shareRegistry.forEach((registry) => {
                    shareholders.push(
                        <TableRow key={registry.SHCNumber}>
                            <TableData element={registry.SHCNumber}/>
                            <TableData element={registry.numberOfShares}/>
                            <TableData element={registry.ownershipPercentage}/>
                            <TableData element={registry.dateGiven}/>
                            <TableData element={registry.shareholderName}/>
                            <TableData element={registry.shareholderAddress}/>
                            <TableData element={registry.shareholderIDNumber}/>
                            <TableData
                                element={<Link to={`/team/shareregistry/edit/${encodeURIComponent(registry.id)}`}>
                                    <button><i className={"fa fa-edit"}/></button>
                                </Link>}/>
                        </TableRow>
                    );
                });

                containers.push(
                    <FullInformation
                        key={teamRegistry.teamName}
                        name={teamRegistry.teamName}
                        active={activeTab === index}
                    >

                        <Link to={`/team/shareregistry/create/${encodeURIComponent(teamRegistry.teamCode)}`}>
                            <button className={"right"}>
                                Add shareholder certificate&nbsp;<i className="fa-solid fa-circle-plus"></i>
                            </button>
                        </Link>

                        {shareholders.length > 0 ? (
                            <Table
                                key={teamRegistry.teamName}
                                head={[
                                    "SHC Number",
                                    "Number of Shares",
                                    "Ownership Percentage",
                                    "Date Given",
                                    "Shareholder Name",
                                    "Shareholder Address",
                                    "Shareholder ID Number",
                                    "Edit"
                                ]}
                            >
                                {shareholders}
                            </Table>
                        ) : (
                            <p>No shareholder certificates registered yet.</p>
                        )}
                    </FullInformation>
                );
            });

            setContainers(containers);
        });
    }, [activeTab]);

    function slickChange(currentSlide: number, nextSlide: number) {
        sessionStorage.setItem("slick-tab-share", String(nextSlide));
        setActiveTab(nextSlide);
    }

    return (
        <Dashboard
            title={"Share Registry"}
            className={"share-registry"}
            slickChange={slickChange}
            sliderKey={"share"}
        >
            {containers}
        </Dashboard>
    );
};
