import React from "react";

export const Loading = () => {
    return (
        <>
            <div style={{marginTop: "50vh"}}/>
            <div
                className={"fa-3x position-absolute top-50 start-50 translate-middle"}
            >
                <i className="fas fa-spinner fa-pulse"/>
            </div>
        </>
    );
};