import UserWrapper from "./UserWrapper";
import DetailedResponse from "../../DetailedResponse";

class GetUserExists extends UserWrapper {
    request(uuid: string, token: string): Promise<DetailedResponse<string[]>> {
        return this.detailedFormattedRequest<string[]>(
            `/exists/${encodeURIComponent(uuid)}`,
            "GET",
            token,
            undefined
        );
    }
}

export default new GetUserExists();