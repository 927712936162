import {IViewableVideo} from "../../../backend/model/user/viewables/VideoPlaylists";

export const Video = (props: IViewableVideo) => {
    return (
        <section className={"col"}>
            <div
                className={"embed"}
                dangerouslySetInnerHTML={{__html: props.embed.html}}
            />
            <h4>{props.name}</h4>
        </section>
    );
};