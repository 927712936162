import DetailedResponse from "../../../DetailedResponse";
import ProgrammeFormList from "../../../model/team/supervisor/ProgrammeFormList";
import TeamWrapper from "../TeamWrapper";

class ListForms extends TeamWrapper {
    async request(token: string): Promise<DetailedResponse<ProgrammeFormList>> {
        let request = await this.detailedFormattedRequest<string[][][]>(
            "/supervisor/list/tasks",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        return new DetailedResponse(
            request.status,
            request.text,
            new ProgrammeFormList(request.additionalInformation)
        );
    }
}

export default new ListForms();