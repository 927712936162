import React, {useEffect, useState} from "react";
import {Dashboard} from "../../elements/dashboard/Dashboard";
import TokenUserCache from "../../backend/cache/TokenUserCache";
import ChangeUserInformation from "../../backend/wrapper/user/change/ChangeUserInformation";
import {Row} from "../../elements/dashboard/container/Row";
import {Column} from "../../elements/dashboard/container/elements/Column";
import {Information} from "../../elements/dashboard/container/elements/Information";
import {Button} from "../../elements/dashboard/container/elements/Button";
import {FullInformation} from "../../elements/dashboard/FullInformation";

export const Profile = () => {
    const [containers, setContainers] = useState<JSX.Element[]>([]);
    const [editMode, setEditMode] = useState(false);

    const [dateOfBirth, setDateOfBirth] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let containers: JSX.Element[] = [];

        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.user.then((user) => {
            if (user === undefined) return;

            if (dateOfBirth === "") setDateOfBirth(new Date(user.dateOfBirth).toLocaleDateString("en-MT", {day: "numeric", month: 'short', year: "numeric"}));
            if (phoneNumber === "") setPhoneNumber(user.mobile);
            if (email === "") setEmail(user.email);
            if (description === "")
                setDescription(
                    user.description
                        ? user.description
                        : "Describe yourself in a couple of sentences."
                );

            containers.push(
                <Row title={"General Information"} key={"general-information"}>
                    <Column key={"name"}>
                        <Information
                            subtitle={"Full Name"}
                            key={"full-name"}
                            information={user.name}
                        />
                    </Column>

                    <Column key={"dob"} setEditMode={setEditMode} editMode={editMode}>
                        <Information
                            subtitle={"Date of Birth"}
                            editMode={editMode}
                            onChange={setDateOfBirth}
                            key={"dob"}
                            information={dateOfBirth}
                            dataType={"date"}
                        />
                    </Column>

                    <Column key={"id-number"}>
                        <Information
                            subtitle={"ID Number"}
                            key={"id"}
                            information={user.idNumber}
                        />
                    </Column>

                    <Column
                        key={"phone-number"}
                        setEditMode={setEditMode}
                        editMode={editMode}
                    >
                        <Information
                            subtitle={"Phone Number"}
                            onChange={setPhoneNumber}
                            editMode={editMode}
                            key={"phone-number"}
                            information={phoneNumber}
                            dataType={"tel"}
                        />
                    </Column>

                    <Column key={"email"} setEditMode={setEditMode} editMode={editMode}>
                        <Information
                            subtitle={"Email"}
                            onChange={setEmail}
                            editMode={editMode}
                            key={"email"}
                            information={email}
                            dataType={"email"}
                        />
                    </Column>
                </Row>,

                <Row title={"Personal Information"} key={"personal-information"}>
                    <Column key={"address"}>
                        <Information
                            subtitle={"Home Address"}
                            information={user.address?.street}
                        />
                    </Column>

                    <Column key={"city"}>
                        <Information subtitle={"City"} information={user.address?.city}/>
                    </Column>

                    <Column key={"country"}>
                        <Information
                            subtitle={"Country"}
                            information={user.address?.country}
                        />
                    </Column>

                    <Column key={"postal-code"}>
                        <Information
                            subtitle={"Postal Code"}
                            information={user.address?.postalCode}
                        />
                    </Column>

                    <Column key={"nationality"}>
                        <Information
                            subtitle={"Nationality"}
                            information={user.address?.country}
                        />
                    </Column>
                </Row>,

                <Row title={"Profile Token"} key={"profile-token"}>
                    <Column key={"token"}>
                        <Information subtitle={user.token} key={"token"} information={""}/>
                    </Column>
                </Row>,

                <Row title={"Other"} key={"other"}>
                    <Column setEditMode={setEditMode} editMode={editMode}>
                        <Information
                            subtitle={"About Me"}
                            onChange={setDescription}
                            editMode={editMode}
                            key={"about"}
                            information={description}
                            dataType={"textarea"}
                        />
                    </Column>
                </Row>,

                <Row title={"Username & Password"} key={"username-password"}>
                    <Column key={"username"}>
                        <Button
                            text={"Username"}
                            buttonText={"Change"}
                            url={"/change/username"}
                            external={false}
                            key={"change-username"}
                        />
                    </Column>

                    <Column key={"password"}>
                        <Button
                            text={"Password"}
                            buttonText={"Change"}
                            url={"/change/password"}
                            external={false}
                            key={"change-password"}
                        />
                    </Column>
                </Row>
            );
            setContainers(containers);
        });
    }, [editMode, dateOfBirth, email, phoneNumber, description]);

    function onCancel() {
        setDateOfBirth("");
        setPhoneNumber("");
        setEmail("");
        setDescription("");
    }

    function onSubmit() {
        setLoading(true);
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        let body: any = {};

        if (phoneNumber?.trim() !== "") body["phoneNumber"] = phoneNumber;
        if (email?.trim() !== "") body["email"] = email;
        if (description?.trim() !== "") body["description"] = description;
        if (dateOfBirth !== undefined && dateOfBirth !== "")
            body["dateOfBirth"] = dateOfBirth;

        ChangeUserInformation.request(
            body.dateOfBirth,
            body.email,
            body.phoneNumber,
            body.description,
            userCache.token
        ).then((value) => {
            if (value.status === 200 || value.status === 208) {
                onCancel();
                setLoading(false);
                setEditMode(false);
                TokenUserCache.invalidateCache([userCache.token]);
            } //Data updated successfully
            else {
                alert(
                    "An unexpected error happened during update profile information: " +
                    value.text
                );
                onCancel();
                setEditMode(false);
                setLoading(false);
            }
        });
    }

    return (
        <>
            <Dashboard title={"User Profile"} className={"team-profile"}>
                <FullInformation
                    active={true}
                    key={""}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    loading={loading}
                >
                    {containers}
                </FullInformation>
            </Dashboard>
        </>
    );
};
