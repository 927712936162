import React, {Dispatch, SetStateAction} from "react";
import {EditButton} from "../EditButton";

interface IColumnInformation {
    children: React.ReactNode | React.ReactNode[];
    setEditMode?: Dispatch<SetStateAction<any>>;
    editMode?: boolean;
}

export const Column = (props: IColumnInformation) => {
    return (
        <div
            className={props.setEditMode !== undefined ? "col-sm editable" : "col-sm"}
        >
            {props.setEditMode !== undefined && !props.editMode && (
                <EditButton setEditMode={props.setEditMode}/>
            )}
            {props.children}
        </div>
    );
};
