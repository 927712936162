import BaseObject from "../BaseObject";

export default class ArticleList extends BaseObject {
    formattedArticles: IFormattedArticle[];

    constructor(formattedArticles: IFormattedArticle[]) {
        super();
        this.formattedArticles = formattedArticles;
    }
}

export interface IFormattedArticle {
    id: string;
    name: string;
}