import BaseObject from "../../BaseObject";

export default class EventList extends BaseObject{
    events: IEventProgrammeList[] = [];

    constructor(eventList: IEventProgrammeList[]) {
        super();
        this.events = eventList;
    }
}

export interface IEventProgrammeList {
    programmeName: string;
    events: IEventList[]
}

export interface IEventList {
    name: string;
    startDate: Date;
    endDate: Date;
    type: string;
    description: string;
    location: string;
    status: "Upcoming" | "Pending Reporting" | "Unattended" | "Attended" | "Excused";
}