import React from "react";
import "./index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";

const container = document.getElementById("root");
if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </React.StrictMode>
    );
}

