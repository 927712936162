import BaseObject from "../../BaseObject";

export default class VideoPlaylists extends BaseObject {
    videoPlaylists: IVideoPlaylist[];

    constructor(videoPlaylists: IVideoPlaylist[]) {
        super();
        this.videoPlaylists = videoPlaylists;
    }
}

export interface IVideoPlaylist {
    name: string;
    logoUrl: string;
    videos: IViewableVideo[];
}

export interface IViewableVideo {
    name: string;
    embed: IYoutubeVideoEmbed;
}

export interface IYoutubeVideoEmbed {
    title: string;
    type: string;
    height: string;
    width: string;
    thumbnailHeight: string;
    thumbnailWidth: string;
    thumbnailUrl: string;
    html: string;
}