import {IViewableForm} from "../../../backend/model/team/TaskList";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export const Task = (props: IViewableForm) => {
    const [display, setDisplay] = useState(false);
    const [colour, setColour] = useState("");
    const [started, setStarted] = useState(false);

    const [formattedStartDate, setFormattedStartDate] = useState("");
    const [formattedEndDate, setFormattedEndDate] = useState("");
    const navigate = useNavigate();

    function displayDescription() {
        setDisplay(!display);
    }

    function onFormFillInPress() {
        if (!props.formId) return;
        else if (
            (props.completed && !props.repeatable) ||
            (props.repeatable && props.passedDeadline && props.completed)
        )
            navigate(`../team/form/${props.formId}/results`);
        else navigate(`../team/form/${props.formId}`);
    }

    useEffect(()=> {
        setFormattedStartDate(new Date(props.startDate).toLocaleDateString("en-MT", {day: "numeric", month: 'short', year: "numeric"}));
        setFormattedEndDate(new Date(props.endDate).toLocaleDateString("en-MT", {day: "numeric", month: 'short', year: "numeric"}));
    }, [props.startDate, props.endDate]);

    useEffect(() => {
        setStarted(new Date(props.startDate) < new Date());
    }, [props.startDate])

    useEffect(() => {
        if (props.deadlineToday && !props.completed) setColour("orange");
        else if (props.passedDeadline && !props.completed) setColour("red");
    }, [props.completed, props.deadlineToday, props.passedDeadline]);

    return (
        <section className={"task"}>
            <div className={"information " + colour}>
        <span className={"title"}>
          {props.completed &&
              (!props.repeatable ||
                  (props.repeatable && props.passedDeadline)) && (
                  <i className={"fa-regular fa-square-check text-white"}/>
              )}
            {props.completed && props.repeatable && !props.passedDeadline && (
                <span
                    className={"fa-stack"}
                    style={{fontSize: "0.5em", marginBottom: "0.3vh"}}
                >
              <i className={"fas fa-regular fa-square fa-stack-2x"}/>
              <i className={"fas fa-solid fa-square fa-stack-1x"}/>
            </span>
            )}
            {!props.completed && (
                <i className={"fa-regular fa-square text-white"}/>
            )}
            &nbsp; {props.name}
        </span>

                <div className={"right d-inline"}>
                    <span className={"due-date"}>Due Date - {formattedEndDate}</span>
                    <button
                        type={"button"}
                        className={"open"}
                        onClick={displayDescription}
                    >
                        <div className={"rotate " + (display ? "rotate-down" : "")}>
                            <i className={"fa-solid fa-circle-chevron-down"}/>
                        </div>
                    </button>
                </div>
            </div>

            <div className={"description " + (display ? "active " : "") + colour}>
                <h3>{props.name}</h3>
                <p>{props.description}</p>
                <button
                    type={"button"}
                    onClick={onFormFillInPress}
                    className={!props.formId ? "grey " : ""}
                >
                    {props.completed && props.passedDeadline
                        ? "View Submission"
                        : "Fill in"}
                    {!started && (
                        <>
                            <span>Available from: {formattedStartDate}</span>
                        </>
                    )}
                </button>
            </div>
        </section>
    );
};
