import "./article.css";
import basicBlocks from "grapesjs-blocks-basic"
// @ts-ignore
import flexBox from "grapesjs-blocks-flexbox";
//@ts-ignore
import gradientStyle from "grapesjs-style-gradient"

import {useEffect} from "react";
import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {useParams} from "react-router-dom";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {grapesjs, Editor} from "grapesjs";

export const ArticleEditor = () => {
    const {id} = useParams();

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );


        let editor = grapesjs.init({
            container: "#gjs",
            plugins: [basicBlocks, flexBox, gradientStyle],
            storageManager: {
                type: "remote",
                stepsBeforeSave: 2,
                autosave: true,
                options: {
                    remote: {
                        urlLoad: `${process.env["REACT_APP_API_URL"]}/admin/article/${encodeURIComponent(id as string)}`,
                        urlStore: `${process.env["REACT_APP_API_URL"]}/admin/article/${encodeURIComponent(id as string)}`,
                        credentials: "omit",
                        headers: {Authorization: userCache.token},
                        contentTypeJson: true,
                        // The `remote` storage uses the POST method when stores data but
                        // the json-server API requires PATCH.
                        fetchOptions: (opts: any) => (opts.method === 'POST' ? {method: 'PATCH'} : {}),
                        // As the API stores projects in this format `{id: 1, data: projectData }`,
                        // we have to properly update the body before the store and extract the
                        // project data from the response result.
                        onStore: (data: any, editor: Editor) => {
                            const pagesHtml = editor.Pages.getAll().map((page: any) => {
                                const component = page.getMainComponent();
                                return {
                                    html: editor.getHtml({component}),
                                    css: editor.getCss({component})
                                }
                            });
                            return ({data: data, rendered: pagesHtml[0]} as any);
                        },
                        onLoad: result => result.additionalInformation.data,
                    }
                }
            }
        });


        //Add the save button to the toolbar
        editor.Panels.addButton('options', {
            id: 'save-db',
            className: 'fa fa-floppy-o',
            command: 'save-db',
            attributes: {title: 'Save'},
            label: "",
            options: {},
            active: false,
            buttons: [],
            cid: "",
            collection: undefined,
            context: "",
            disable: false,
            dragDrop: false,
            runDefaultCommand: false,
            stopDefaultCommand: false,
            tagName: "span",
            togglable: false
        });
        //Add the save button to the toolbar

        //Add the command save-db which sends the save signal
        editor.Commands.add('save-db', {
            run: (editor: Editor, sender?: any) => {
                sender && sender.set('active'); // turn off the button
                editor.store({}).then(() => console.log("Saved."));
            }
        });
        //Add the command save-db which sends the save signal

        //Add JA Malta Brand Fonts
        const fontProperty = editor.StyleManager.getProperty('typography', 'font-family');
        if(!fontProperty) return;
        fontProperty.set({name: "options"},[
            {value: "'Montserrat', sans-serif", name: 'Montserrat'},
            {value: "'Work Sans', sans-serif", name: 'Work Sans'},
            {value: "'Allura', cursive", name: 'Allura'},
            {value: "'Yesteryear', cursive", name: 'Yesteryear'},
        ]);

        fontProperty.set('default', "'Montserrat', sans-serif");
        fontProperty.set('defaults', "'Montserrat', sans-serif");

        editor.Css.setRule('*', {"font-family": "'Montserrat', sans-serif"}); //Set Montserrat as the default font
        //Add JA Malta Brand Fonts
    }, [id])

    return (
        <Dashboard title={"Article"}>
            <div id={"gjs"}/>
        </Dashboard>
    )
}