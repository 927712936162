import BaseObject from "../../BaseObject";

export default class UserList extends BaseObject {
    users: IUserView[];

    constructor(users: IUserView[]) {
        super();
        this.users = users;
    }
}

export interface IUserView {
    id: string;
    name: string;
    email: string;
    idCard: string;
    portalAccount: boolean | string;
    studentInTeam: boolean;
}