import BaseObject from "../BaseObject";

export default class ShareRegistryInformation extends BaseObject {
    shareRegistryInformation: IShareRegistryInformation[];

    constructor(shareRegistryInformation: IShareRegistryInformation[]) {
        super();
        this.shareRegistryInformation = shareRegistryInformation;
    }
}

export interface IShareRegistryInformation {
    teamName: string;
    teamCode: string;
    shareRegistry: IViewableShareholderCertificate[];
}

export interface IViewableShareholderCertificate {
    id: string;
    SHCNumber: string;
    numberOfShares: string;
    ownershipPercentage: string;
    dateGiven: string;
    shareholderName: string;
    shareholderAddress: string;
    shareholderIDNumber: string;
}