interface IChapter {
    children: JSX.Element | JSX.Element[];
    title: string;
    blurred: boolean;
}

export const Chapter = (props: IChapter) => {
    return (
        <section className={"phase-container " + (props.blurred ? "blurred" : "")}>
            <p className={"phase-unavailable"}>Please read the chapter above to access this chapter.</p>
            <section className={"phase"}>
                <h2>{props.title}</h2>
                {props.children}
            </section>
        </section>
    );
};