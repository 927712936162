import UserWrapper from "./UserWrapper";
import GeneralResponse from "../../GeneralResponse";

class CreateUserWrapper extends UserWrapper {
    async request(
        username: string,
        password: string,
        idCard: string
    ): Promise<GeneralResponse> {
        return await this.formattedRequest("", "POST", undefined, {
            username: username,
            password: password,
            id: idCard,
        });
    }
}

export default new CreateUserWrapper();