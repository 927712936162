import {ReactElement, useEffect, useState} from "react";
import {IEventList} from "../../../backend/model/user/events/EventList";

export const Event = (props: IEventList) => {
    const [display, setDisplay] = useState(false);
    const [colour, setColour] = useState("");
    const [icon, setIcon] = useState<ReactElement | undefined>(undefined);

    const [formattedStartDate, setFormattedStartDate] = useState("");

    function displayDescription() {
        setDisplay(!display);
    }

    useEffect(() => {
        setFormattedStartDate(new Date(props.startDate).toLocaleDateString("en-MT", {
            day: "numeric",
            month: 'short',
            year: "numeric"
        }));
    }, [props.startDate]);


    useEffect(() => {
        let status = props.status;
        if (status === "Unattended") {
            setColour("red");
            setIcon(<i className='fa-regular fa-square-x text-white'></i>);
        } else if (status === "Attended") {
            setColour("green");
            setIcon(<i className='fa-regular fa-square-check text-white'></i>);
        } else if (status === "Excused") {
            setColour("yellow");
            setIcon(<span className={"fa-stack"} style={{fontSize: "0.5em", marginBottom: "0.3vh"}}>
              <i className={"fas fa-regular fa-square fa-stack-2x"}/>
              <i className={"fas fa-solid fa-square fa-stack-1x"}/>
            </span>);
        } else {
            setColour("blue");
            setIcon(<i className='fa-regular fa-square text-white'></i>);
        }
    }, [props.status]);

    return (
        <section className={"task"}>
            <div className={"information event " + colour}>

        <span className={"title"}>
            {icon} &nbsp; {props.name}
        </span>
                <div className={"right d-inline"}>
                    <span className={"due-date"}> Event Date: {formattedStartDate}</span>
                    <button
                        type={"button"}
                        className={"open"}
                        onClick={displayDescription}
                    >
                        <div className={"rotate " + (display ? "rotate-down" : "")}>
                            <i className={"fa-solid fa-circle-chevron-down"}/>
                        </div>
                    </button>
                </div>
            </div>

            <div className={"description event " + (display ? "active " : "") + colour}>
                <h3>{props.name}</h3>
                <p>{props.description}</p>
                <p><i className="fa-regular fa-business-time"></i> Duration: {new Date(props.startDate).toLocaleTimeString("en-MT", {hour: '2-digit', minute: '2-digit'})} till {new Date(props.endDate).toLocaleTimeString("en-MT", {hour: '2-digit', minute: '2-digit'})}</p>
                {!props.location ? "" :
                    <p><i className="fa-regular fa-location-dot"></i> Location: {props.location}</p>}
                {props.status === "Pending Reporting" ? "" :
                    <p><i className="fa-regular fa-circle-info"></i> Status: {props.status}</p>}
            </div>
        </section>
    );
};
