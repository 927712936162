import React, {useState} from "react";
import {GenericBox} from "../../../elements/GenericBox";
import UseForgotToken from "../../../backend/wrapper/auth/forgot/UseForgotToken";
import {FormPage} from "../../../elements/form/FormPage";
import {FormItem} from "../../../elements/form/FormItem";
import {useParams} from "react-router-dom";

export const UseForgot = () => {
    const {id} = useParams();

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [showBox, setShowBox] = useState(false);

    async function validateData(): Promise<string | undefined> {
        if (newPassword.trim() !== confirmNewPassword.trim())
            return "The passwords entered do not match.";
    }

    async function submit(): Promise<string | undefined> {
        const useForgot = await UseForgotToken.request(id as string, newPassword);
        if (useForgot.status !== 200) return useForgot.text;
        else setShowBox(true);
    }

    return (
        <>
            {!showBox ? (
                <main id={"small-box"} className={"right-blue-border"}>
                    <FormPage
                        title={"CHANGE PASSWORD"}
                        currentState={"submit"}
                        validateData={validateData}
                        submit={submit}
                    >
                        <FormItem
                            id={"new-password"}
                            label={"Password"}
                            inputType={"password"}
                            required={true}
                            onChange={setNewPassword}
                            initialValue={""}
                        />
                        <FormItem
                            id={"confirm-new-password"}
                            label={"Confirm Password"}
                            inputType={"password"}
                            required={true}
                            onChange={setConfirmNewPassword}
                            initialValue={""}
                        />
                    </FormPage>
                </main>
            ) : (
                <GenericBox
                    title={"Password changed!"}
                    text={
                        "Your password was successfully changed.  Please login using your new password."
                    }
                    image={"/image/authentication/registration-complete.svg"}
                />
            )}
        </>
    );
};
