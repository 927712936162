import UserWrapper from "../UserWrapper";
import DetailedResponse from "../../../DetailedResponse";
import VideoPlaylists from "../../../model/user/viewables/VideoPlaylists";

class GetVideoPlaylists extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<VideoPlaylists>> {
        let request = await this.formattedRequest(
            "/viewables/video/playlist",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        let viewable = new VideoPlaylists(request.additionalInformation);
        return new DetailedResponse<VideoPlaylists>(200, request.text, viewable);
    }
}

export default new GetVideoPlaylists();