import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {Table} from "../../../elements/table/Table";
import {useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {TableRow} from "../../../elements/table/TableRow";
import {TableData} from "../../../elements/table/TableData";

export const LeadList = () => {
    const [table, setTable] = useState<JSX.Element[] | undefined>(undefined);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        userCache.supervisorLeadList.then((list) => {
            let tableInformation: JSX.Element[] = [];
            if (list === undefined) {
                setTable([]);
                return;
            }

            list.leads.forEach((user) => {
                let consentUploaded =
                    typeof user.consentUploaded === "boolean" ? (
                        <i className="far fa-check-square text-black-50"/>
                    ) : (
                        <a target={"_blank"} rel={"noreferrer"} href={user.consentUploaded}>
                            <button>Consent URL</button>
                        </a>
                    );
                let paymentReceived =
                    typeof user.paymentReceived === "boolean" ? (
                        <i className="far fa-check-square text-black-50"/>
                    ) : (
                        <a target={"_blank"} rel={"noreferrer"} href={user.paymentReceived}>
                            <button>Payment URL</button>
                        </a>
                    );

                tableInformation.push(
                    <TableRow key={user.idCard}>
                        <TableData element={user.name}/>
                        <TableData element={user.email} url={`mailto:${user.email}`}/>
                        <TableData element={user.idCard}/>
                        <TableData
                            element={
                                user.consentRequested ? (
                                    <i className={"far fa-check-square text-black-50"}/>
                                ) : (
                                    <i className={"far fa-square text-black-50"}/>
                                )
                            }
                        />
                        <TableData element={consentUploaded}/>
                        <TableData element={paymentReceived}/>
                        <TableData element={user.internalStatus}/>
                    </TableRow>
                );
            });
            setTable(tableInformation);
        });
    }, []);

    return (
        <Dashboard title={"Pending Student Registrations"} noSlider={true}>
            {table ? (
                <>
                    {table.length > 0 ?
                        <>
                            <p>Please note that this list might take up to 8 hours to update.</p>
                            <Table download={true}
                                               head={[
                                                   "Full Name",
                                                   "Email",
                                                   "ID Card Number",
                                                   "Consent Requested",
                                                   "Consent Uploaded",
                                                   "Payment Received",
                                                   "Status",
                                               ]}
                            >
                                {table}
                            </Table>
                            </>:
                        <p>There are no pending leads.</p>}

                </>
            ) : undefined}
        </Dashboard>
    );
};
