import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {FormItem} from "../../../elements/form/FormItem";
import {useState} from "react";
import {FormPage} from "../../../elements/form/FormPage";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import renameArticle from "../../../backend/wrapper/admin/article/RenameArticle";

export const RenameArticle = () => {
    const {id} = useParams();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [name, setName] = useState<string>("");

    async function validateData(): Promise<string | undefined> {
        return undefined;
    }

    async function onSubmit(): Promise<string | undefined> {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        let request = await renameArticle.request(id as string, name, userCache.token);
        if (request.status !== 200) return request.text;

        TokenUserCache.invalidateCache([userCache.token]);
        navigate("/admin/article/list");
        return undefined;
    }

    return (
        <main id={"small-box"} className={"right-blue-border"}>
            <FormPage title={`Rename Article with ID: ${id}`} validateData={validateData} submit={onSubmit}
                      currentState={"submit"}>
                <FormItem id={"name"} label={"New Name"} inputType={"text"} required={true} onChange={setName}
                          initialValue={searchParams.get("oldName") ?? ""}/>
            </FormPage>
        </main>
    )

}