interface ITableData {
    element: string | JSX.Element | JSX.Element[];
    url?: string;
}

export const TableData = (props: ITableData) => {
    return (
        <td className={"table-no-border"}>
            {props.url ? (
                <a href={props.url} target={"_blank"} rel={"noreferrer"}>
                    {props.element}
                </a>
            ) : (
                <>{props.element}</>
            )}
        </td>
    );
};