import axios, {AxiosResponse, Method} from "axios";
import GeneralResponse from "../GeneralResponse";
import DetailedResponse from "../DetailedResponse";

export default abstract class BaseWrapper {
    static readonly baseUrl = process.env["REACT_APP_API_URL"];
    protected abstract url: string;
    contentType: string = "application/json";

    /**
     * Response for when sending a request
     * @param endpoint The endpoint of the url
     * @param method The method of the url
     * @param token The authentication token (leave empty in case of no auth)
     * @param body The body of the request
     * @protected
     */
    protected async unformattedRequest(
        endpoint: string,
        method: Method,
        token: string | undefined,
        body: any
    ): Promise<AxiosResponse> {
        if (token === undefined) token = "";
        if (endpoint === undefined) endpoint = "";
        if (body === undefined) body = {};

        return await axios.request({
            url: `${BaseWrapper.baseUrl}${this.url}${endpoint}`,
            method: method,
            headers: {
                "Content-Type": this.contentType,
                Authorization: token,
            },
            data: body,
        });
    }

    /**
     * Returns a general response when sending a normal request
     * @param endpoint The endpoint of the url
     * @param method The method of the url
     * @param token The authentication token (leave empty in case of no auth)
     * @param body The body of the request
     * @protected
     */
    protected async formattedRequest(
        endpoint: string,
        method: Method,
        token: string | undefined,
        body: any
    ): Promise<GeneralResponse> {
        try {
            let request = await this.unformattedRequest(
                endpoint,
                method,
                token,
                body
            );
            return new GeneralResponse(
                request.status,
                request.data.text,
                request.data.additionalInformation
            );
        } catch (error: any) {
            if (error.response?.data?.text)
                return new GeneralResponse(
                    error.response.status,
                    error.response.data.text,
                    error.response.data.additionalInformation
                );
            else {
                console.error("An unknown error has occurred");
                console.error(error);
                return new GeneralResponse(500, "Internal server error", undefined);
            }
        }
    }

    /**
     * Returns a detailed response for when having an interface extended
     * @param endpoint The endpoint of the url
     * @param method The method of the url
     * @param token The authentication token (leave empty in case of no auth)
     * @param body The body of the request
     * @protected
     */
    protected async detailedFormattedRequest<T>(
        endpoint: string,
        method: Method,
        token: string,
        body: any
    ): Promise<DetailedResponse<T>> {
        return (await this.formattedRequest(
            endpoint,
            method,
            token,
            body
        )) as DetailedResponse<T>;
    }

    abstract request(...data: any): Promise<any>;
}
