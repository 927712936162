import React, {useEffect, useState} from "react";
import "../notification/notification.css";
import isAuthenticated from "../../../helpers/isAuthenticated";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {NotificationItem} from "./NotificationItem";

export const Notification = () => {
    const [icon, setIcon] = useState<JSX.Element | undefined>(undefined);
    const [notificationButtonClass, setNotificationButtonClass] =
        useState<string>("");
    const [notifications, setNotifications] = useState<JSX.Element[] | undefined>(
        undefined
    );
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isHidden, setIsHidden] = useState<boolean>(false);

    function toggleActive() {
        setIsActive(!isActive);
    }

    useEffect(() => {
        if (!isAuthenticated()) return;
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        userCache.notificationBell.then((items) => {
            if (items === undefined) {
                localStorage.removeItem("token");
                window.location.href = "/";
                return;
            }

            let notifications = items.notificationBell;
            if (!notifications || notifications.length === 0) {
                setIsHidden(true);
                return;
            }

            if (notifications.filter((value) => !value.seen).length > 0) {
                setIcon(<i className="fas fa-bell-exclamation"/>);
                setNotificationButtonClass("unread");
            } else {
                setIcon(<i className="far fa-bell"/>);
                setNotificationButtonClass("");
            }

            let notificationItems: JSX.Element[] = [];
            notifications.forEach((value) =>
                notificationItems.push(
                    <NotificationItem
                        id={value.id}
                        typeOfNotification={value.typeOfNotification}
                        title={value.title}
                        message={value.message}
                        seen={value.seen}
                        key={value.id}
                    />
                )
            );

            setNotifications(notificationItems);
            return;
        });
    }, [isHidden]);

    if (!isAuthenticated()) return <></>;

    return (
        <section id={"notification-area"} className={isHidden ? "hidden" : ""}>
            <button
                id={"notification-button"}
                className={notificationButtonClass}
                onClick={toggleActive}
            >
                {icon}
            </button>

            <div id={"notification-box"} className={isActive ? "active" : ""}>
                {notifications}
                <hr/>
            </div>
        </section>
    );
};
