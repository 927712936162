import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import GetSingleShareInformation from "../../../backend/wrapper/team/shares/GetSingleShareInformation";
import {FormPage} from "../../../elements/form/FormPage";
import {FormItem} from "../../../elements/form/FormItem";
import UpdateShareRegistryInformation, {
    IUpdateShareRegistry
} from "../../../backend/wrapper/team/shares/UpdateShareRegistryInformation";

export const EditShareRegistry = () => {
    const {id} = useParams();

    const [certificateNumber, setCertificateNumber] = useState<number | undefined>(undefined);
    const [numberOfShares, setNumberOfShares] = useState<number | undefined>(undefined);
    const [shareholderDate, setShareholderDate] = useState<string | undefined>(undefined);
    const [shareholderName, setShareholderName] = useState<string | undefined>(undefined);
    const [shareholderIDNumber, setShareholderIDNumber] = useState<string | undefined>(undefined);
    const [shareholderAddress, setShareholderAddress] = useState<string | undefined>(undefined);
    const [chitUpload, setChitUpload] = useState<FileList | undefined>(undefined);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        GetSingleShareInformation.request(id as string, userCache.token).then(value => {
            //Convert dd/mm/yyyy to yyyy-mm-dd
            const [day, month, year] = (value.additionalInformation.dateGiven).split("/");
            let formattedDate = [year, month, day].join('-');
            //Convert dd/mm/yyyy to yyyy-mm-dd

            setCertificateNumber(value.additionalInformation.SHCNumber as unknown as number);
            setNumberOfShares(value.additionalInformation.numberOfShares.slice(1) as unknown as number);
            setShareholderDate(formattedDate);
            setShareholderName(value.additionalInformation.shareholderName);
            setShareholderIDNumber(value.additionalInformation.shareholderIDNumber);
            setShareholderAddress(value.additionalInformation.shareholderAddress);
        });
    }, [id]);

    async function validateData(): Promise<string | undefined> {
        if (!certificateNumber || !numberOfShares || !shareholderDate || !shareholderName || !shareholderIDNumber || !shareholderAddress) return "Please fill in all the fields.";

        if (numberOfShares > 25) return "The maximum amount of shares is €25";
        else if (new Date(shareholderDate) > new Date()) return "Shareholder Date cannot be in the future.";
        else if (chitUpload && chitUpload[0].size > 4 * 1024 * 1024) return "File can't be larger than 4MB";
        else return undefined;
    }

    async function submit(): Promise<string | undefined> {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        let chit = chitUpload !== undefined ? chitUpload[0] : undefined;

        let shareInformation: IUpdateShareRegistry = {
            id: id as string,
            certificateNumber: certificateNumber as number,
            numberOfShares: numberOfShares as number,
            shareholderDate: new Date(shareholderDate as string).toISOString(),
            shareholderName: shareholderName as string,
            shareholderIDNumber: shareholderIDNumber as string,
            shareholderAddress: shareholderAddress as string,
            chit: chit
        }

        let request = await UpdateShareRegistryInformation.request(shareInformation, userCache.token);

        if (request.status === 200) {
            TokenUserCache.invalidateCache([userCache.token]);
            window.location.href = `/team/shareregistry`;
        } else return request.text;
    }


    return (
        <main id={"small-box"} className={"right-blue-border"}>
            <FormPage
                title={"SHAREHOLDER REGISTRY"}
                currentState={"submit"}
                validateData={validateData}
                submit={submit}
            >
                <FormItem
                    id={"certificate-number"}
                    label={"Certificate Number"}
                    inputType={"number"}
                    required={true}
                    onChange={setCertificateNumber}
                    initialValue={certificateNumber as number}
                />

                <FormItem
                    id={"number-of-shares"}
                    label={"Number of Shares/Amount"}
                    inputType={"number"}
                    required={true}
                    onChange={setNumberOfShares}
                    initialValue={numberOfShares as number}
                />

                <FormItem
                    id={"date"}
                    label={"Date"}
                    inputType={"date"}
                    required={true}
                    onChange={setShareholderDate}
                    initialValue={shareholderDate as string}
                />

                <FormItem
                    id={"name"}
                    label={"Shareholder Name"}
                    inputType={"text"}
                    required={true}
                    onChange={setShareholderName}
                    initialValue={shareholderName as string}
                />

                <FormItem
                    id={"id-number"}
                    label={"Shareholder ID Number"}
                    inputType={"text"}
                    required={true}
                    onChange={setShareholderIDNumber}
                    initialValue={shareholderIDNumber as string}
                />

                <FormItem
                    id={"address"}
                    label={"Shareholder Address"}
                    inputType={"textarea"}
                    required={true}
                    onChange={setShareholderAddress}
                    initialValue={shareholderAddress as string}
                />

                <FormItem
                    id={"chit-upload"}
                    label={"Chit Upload (Max 4MB) - Leave blank to keep the currently uploaded chit."}
                    inputType={"file"}
                    required={false}
                    onChange={setChitUpload}
                    initialValue={""}
                />
            </FormPage>
        </main>
    );
}