import {ReactElement} from "react";

interface IEventCategory {
    children: ReactElement | ReactElement[];
    title: string;
    calculation?: string;
}

export const EventCategory = (props: IEventCategory) => {
    return (
        <section className={"phase"}>
            <h2>{props.title}</h2>
            {props.children}
            {props.calculation ?
                <section className={"attended"}>
                    <p className={"attended-amount"}>{props.calculation}% Attendance</p>
                    <p className={"sub-information"}>
                        <span>* Issuing of certificate is at the discretion of the JA Team. The final percentage is calculated at the end of the year.</span>
                        <br/><br/>
                        <span>* Kindly note that attendance might take up to 5 business days to be updated.</span>
                    </p>
                </section>
                : ""
            }
        </section>
    );
};