import React from "react";
import {Link} from "react-router-dom";
import {IViewableSidebarItem} from "../../../backend/model/user/viewables/Sidebar";

export const SidebarItem = (props: IViewableSidebarItem) => {
    let url = props.url;

    if (props.external) {
        return (
            <li>
                <a target={"_blank"} href={url} rel={"noreferrer"}>
                    <i className={props.icon}/>{props.name}
                </a>
            </li>
        );
    } else {
        url = props.url.replace("https://portal.jamalta.org", "");
        return (
            <li key={props.name}>
                <Link to={url} target={props.external ? "_blank" : "_self"}>
                    <i className={props.icon}/>{props.name}
                </Link>
            </li>
        );
    }
};
