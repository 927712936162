import {Link} from "react-router-dom";

export const NotFound = () => {
    return (
        <main id={"split-box"}>
            <section id="left">
                <h1>The page you were trying to look for does not exist.</h1>

                <div>Go back home</div>
                <Link to={"../"}>
                    <button>Home</button>
                </Link>
            </section>

            <section id="right">
                <img src="/image/404.svg" alt="Not Found" height="60%"/>
            </section>
        </main>
    );
};