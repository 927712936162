import React, {useState} from "react";
import CreateUserWrapper from "../../backend/wrapper/user/CreateUserWrapper";
import CreateAccessToken from "../../backend/wrapper/auth/CreateAccessToken";
import GeneralResponse from "../../backend/GeneralResponse";
import {FormPage} from "../../elements/form/FormPage";
import {FormItem} from "../../elements/form/FormItem";

export const Register = () => {
    const [username, setUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setNewConfirmPassword] = useState("");
    const [idCard, setIdCard] = useState("");

    async function validateData(): Promise<string | undefined> {
        if (newPassword.trim() !== confirmNewPassword.trim())
            return "The passwords entered do not match.";
    }

    async function submit(): Promise<string | undefined> {
        const createUser = await CreateUserWrapper.request(
            username,
            newPassword,
            idCard
        );
        if (createUser.status !== 201) return createUser.text;

        const token = await CreateAccessToken.request(username, newPassword);
        if (token instanceof GeneralResponse) return token.text;
        else {
            localStorage.setItem("token", `Bearer ${token}`);
            window.location.href = "/";
        }
    }

    return (
        <main id={"small-box"} className={"right-orange-border"}>
            <FormPage
                title={"REGISTER"}
                currentState={"submit"}
                validateData={validateData}
                submit={submit}
            >
                <FormItem
                    id={"username"}
                    label={"Username"}
                    inputType={"text"}
                    required={true}
                    onChange={setUsername}
                    initialValue={""}
                />
                <FormItem
                    id={"new-password"}
                    label={"New Password"}
                    inputType={"password"}
                    required={true}
                    onChange={setNewPassword}
                    initialValue={""}
                />
                <FormItem
                    id={"confirm-new-password"}
                    label={"Confirm New Password"}
                    inputType={"password"}
                    required={true}
                    onChange={setNewConfirmPassword}
                    initialValue={""}
                />
                <FormItem
                    id={"id-card"}
                    label={"ID Card"}
                    inputType={"text"}
                    required={true}
                    onChange={setIdCard}
                    initialValue={""}
                />
            </FormPage>
        </main>
    );
};
