import React, {useEffect, useState} from "react";
import Slider, {Settings} from "react-slick";

import "./dashboard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Loading} from "../Loading";

interface IDashboard {
    title: string | JSX.Element;
    noSlider?: boolean;
    className?: string;
    slickChange?: (currentSlide: number, nextSlide: number) => void;
    sliderKey?: string;
    children: JSX.Element[] | JSX.Element | undefined;
}

export const Dashboard = (props: IDashboard) => {
    const [slider, setSlider] = useState<JSX.Element | undefined>(undefined);

    function sliderInit() {
        let sliderPosition = sessionStorage.getItem("slick-tab-" + props.sliderKey);
        if (
            sliderPosition !== null &&
            !isNaN(Number(sliderPosition)) &&
            props.slickChange
        )
            props.slickChange(-1, Number(sliderPosition));
    }

    useEffect(() => {
        if (
            !Array.isArray(props.children) ||
            props.children.length <= 1 ||
            props.noSlider
        )
            return setSlider(undefined); //If there is less than one child, do not show slider

        //Set the initial slide
        let initialSlide = 0;
        let previousSliderPosition = sessionStorage.getItem(
            "slick-tab-" + props.sliderKey
        );
        if (
            previousSliderPosition !== null &&
            !isNaN(Number(previousSliderPosition))
        )
            initialSlide = Number(previousSliderPosition);
        //Set the initial slide

        //Only set the initial slide on first initialisation to not break the animation
        let slickOptions: Settings;
        if (slider === undefined)
            slickOptions = {
                infinite: false,
                slidesToScroll: 1,
                centerPadding: "10px",
                variableWidth: true,
                focusOnSelect: true,
                beforeChange: props.slickChange,
                onInit: sliderInit,
                initialSlide: initialSlide,
            };
        else
            slickOptions = {
                infinite: false,
                slidesToScroll: 1,
                centerPadding: "10px",
                variableWidth: true,
                focusOnSelect: true,
                beforeChange: props.slickChange,
                onInit: sliderInit,
            };
        //Only set the initial slide on first initialisation to not break the animation

        //Get all the items
        let profileProps = props.children?.map((value) => value?.props);
        if (profileProps === undefined) return;

        let sliderInformation: JSX.Element[] = [];
        profileProps.forEach((value) =>
            sliderInformation.push(
                <div className={"slick-item"} key={value.name}>
                    {value.name}
                </div>
            )
        );
        //Get all the items

        //Set the slider
        setSlider(<Slider {...slickOptions}>{sliderInformation}</Slider>);
        //Set the slider
    }, [props.children]);

    return (
        <main id={"full-width"} className={props.className}>
            <h1>{props.title}</h1>
            {slider}
            {(props.children === undefined ||
                (Array.isArray(props.children) && props.children.length === 0) ||
                !props.children) && <Loading/>}
            {props.children}
        </main>
    );
};
