import AdminWrapper from "./AdminWrapper";
import GeneralResponse from "../../GeneralResponse";

class ResetCache extends AdminWrapper {
    async request(
        salesforce: string,
        user: string,
        article: string,
        token: string
    ): Promise<GeneralResponse> {
        let deleteSalesforce = String(salesforce) === "true";
        let deleteUser = String(user) === "true";
        let deleteArticle = String(article) === "true";

        return await this.formattedRequest(
            `/reset/cache`,
            "POST",
            token,
            {
                salesforce: deleteSalesforce,
                user: deleteUser,
                article: deleteArticle
            }
        );
    }
}

export default new ResetCache();