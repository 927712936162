import UserWrapper from "../UserWrapper";
import DetailedResponse from "../../../DetailedResponse";
import UserList, {IUserView} from "../../../model/user/supervisor/UserList";

class GetUserList extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<UserList>> {
        let request = await this.detailedFormattedRequest<IUserView[]>(
            "/supervisor/list/users",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        let users = new UserList(request.additionalInformation);
        return new DetailedResponse<UserList>(request.status, request.text, users);
    }
}

export default new GetUserList();