export default class GeneralResponse {
    status: number;
    text: string;
    additionalInformation: any;

    constructor(status: number, text: string, additionalInformation: any) {
        this.status = status;
        this.text = text;
        this.additionalInformation = additionalInformation;
    }
}
