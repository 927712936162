import {Dashboard} from "../../elements/dashboard/Dashboard";
import {FullInformation} from "../../elements/dashboard/FullInformation";
import {useEffect, useState} from "react";
import {Table} from "../../elements/table/Table";
import TokenUserCache from "../../backend/cache/TokenUserCache";
import {TableRow} from "../../elements/table/TableRow";
import {TableData} from "../../elements/table/TableData";
import DeleteUser from "../../backend/wrapper/admin/DeleteUser";
import {IFormattedUser} from "../../backend/model/admin/UserList";
import GetLoginAs from "../../backend/wrapper/admin/GetLoginAs";
import GeneralResponse from "../../backend/GeneralResponse";

export const ListUsers = () => {
    const [table, setTable] = useState<JSX.Element | undefined>(undefined);
    const [adminUserList, setAdminUserList] = useState<IFormattedUser[]>([]);

    function loginAs(event: any) {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        let username = event.target.id;

        GetLoginAs.request(username, userCache.token).then((value) => {
            if (value instanceof GeneralResponse)
                return alert(
                    "An error has occurred during the Login-As process " + value.text
                );
            localStorage.setItem("token", `Bearer ${value}`);
            window.location.href = "/";
        });
    }

    function deleteUser(event: any) {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        let username = event.target.id;

        if (
            !window.confirm(
                `You are about to delete the user with username: ${username}.  Are you sure?`
            )
        )
            return;

        DeleteUser.request(username, userCache.token).then((value) => {
            if (value.status !== 200)
                return alert(
                    "An error has occurred during student portal account deletion: " +
                    value.text
                );

            let user = adminUserList.findIndex(
                (value) => value.username === username
            );
            if (user === -1)
                return alert("Unable to find user.  Please refresh the page.");

            let copyOfAdminUserList = [...adminUserList];
            copyOfAdminUserList.splice(user, 1);

            setAdminUserList(copyOfAdminUserList);
        });
    }

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.adminUserList.then((adminUserList) => {
            if (adminUserList === undefined) return;
            setAdminUserList(adminUserList.formattedUsers);
        });
    }, []);

    useEffect(() => {
        if (adminUserList.length === 0) return;

        let tableInformation: JSX.Element[] = [];
        adminUserList.forEach((value) => {
            tableInformation.push(
                <TableRow key={value.username}>
                    <TableData element={value.username}/>
                    <TableData element={value.userType}/>
                    <TableData element={value.dateCreated}/>
                    <TableData element={value.salesforceId}/>
                    <TableData
                        element={
                            <button id={value.username} onClick={deleteUser}>
                                <i className={"fa-solid fa-user-slash"}/> Delete User
                            </button>
                        }
                    />
                    <TableData
                        element={
                            <button id={value.username} onClick={loginAs}>
                                <i className={"fa-solid fa-arrow-right-to-bracket"}/> Login As
                            </button>
                        }
                    />
                </TableRow>
            );
        });

        setTable(
            <Table
                head={[
                    "Username",
                    "User Type",
                    "Date Created",
                    "Salesforce ID",
                    "",
                    "",
                ]}
            >
                {tableInformation}
            </Table>
        );
    }, [adminUserList]);

    return (
        <Dashboard title={"Login-As"}>
            <FullInformation key={"list"} active={true}>
                {table}
            </FullInformation>
        </Dashboard>
    );
};
